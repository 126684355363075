import React from 'react';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'react-tabs/style/react-tabs.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AllRoutes from './routes';
import CookieConsent, { Cookies } from "react-cookie-consent";

function App() {
  return (
    <div className="App">
      <AllRoutes />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}

export default App;
