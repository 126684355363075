import React from "react";
import { Navigate } from "react-router-dom";

const UserAccessRoute = ({ children }) => {
    const userId = sessionStorage.getItem("user_id");
    const userName = sessionStorage.getItem("user_name");
    const isLoggedIn = userId && userName;

    return isLoggedIn ? children : <Navigate to="/login" />;
};

export default UserAccessRoute;