import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import './style.css';
import WhiteLogo from "../../../assets/images/whitelogo.svg";
import { apiUrl } from "../../../api/index";
import CookieConsent from "../cookie/index"
function Footer() {
    const [email, setEmail] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            if (!email || email?.trim() == '') {
                setErrorMessage('Please enter a valid email address'); setSuccessMessage('');
                return
            }
            const response = await fetch(`${apiUrl}/v2/newsletter`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                }),
            });

            if (response.ok) {
                setSuccessMessage('Subscription successful!');
                setErrorMessage('');
                // Reset form
                setEmail('');
            } else {
                setErrorMessage('Subscription failed. Please try again.');
                setSuccessMessage('');
            }
        } catch (error) {
            console.error('Error occurred during subscription:', error);
            setErrorMessage('An error occurred. Please try again.');
            setSuccessMessage('');
        }
    };
    return (
        <>
            <div className="footer-section">
                <div className="container">
                    <div class="row newsletter">
                        <div class="col-12 col-md-6 footer-content">
                            <p>Stay informed with the latest updates, exclusive offers, and industry insights. Join now for a dose of valuable information delivered straight to your inbox.</p>
                        </div>
                        <div class="col-12 col-md-6">
                            <form onSubmit={handleSubmit}>
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Enter your work email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <input type="submit" value="Subscribe Now" />
                            </form>
                            {successMessage && <p className="success-message">{successMessage}</p>}
                            {errorMessage && <p className="error-message">{errorMessage}</p>}

                        </div>
                    </div>
                </div>
                <div className="container">
                    <NavLink to={"/"}> <img src={WhiteLogo} className="footerlogo" alt="Logo" /></NavLink>
                </div>
                <div class="container">
                    <div class="row  justify-content-start">
                        <div class="col-12 col-md-3 footer-links">
                            <h4>Contact Us</h4>
                            <ul>
                                <li>Address: <br></br>Federal Boxx LLC, <br></br>2421A N University Drive,<br></br>Coral Springs, FL 330365</li>
                                <li>Email: support@federalboxx.com</li>
                            </ul>
                        </div>

                        <div class="col-12 col-md-2 footer-links">
                            <h4>Company</h4>
                            <ul>
                                <li><NavLink to={"/vision-mission"}>Vision & Mission</NavLink></li>
                                <li><NavLink to={"/contactus"}>Contact Us</NavLink></li>

                            </ul>
                        </div>
                        <div class="col-12 col-md-2 footer-links">
                            <h4>Benefits</h4>
                            <ul>
                                <li><NavLink to={"/shipping-leads"}>Business Leads</NavLink></li>
                                <li><NavLink to={"/networking"}>Networking</NavLink></li>
                                <li><NavLink to={"/faqs"}>Faqs</NavLink></li>
                            </ul>
                        </div>
                        <div class="col-12 col-md-3 footer-links">
                            <h4>Resources</h4>
                            <ul>
                                {/* <li><NavLink to={"/careers"}>Post a Job</NavLink></li>
                                <li><NavLink to={"/careers/listing"}>Careers</NavLink></li> */}
                                <li><NavLink to={"/privacy-policy"}>Privacy Policy</NavLink></li>
                                <li><NavLink to={"/cookie-policy"}>Cookie Policy</NavLink></li>
                                <li><NavLink to={"/terms-and-conditions"}>Terms and Conditions</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyrights">
                <p>All Rights Reserved © FederalBoxx 2023</p>
            </div>
            <CookieConsent></CookieConsent>
            <a className="whats-app" href="https://web.whatsapp.com/send?phone=+971501803692&text=Hi" target="_blank">
                <i className="fa fa-whatsapp my-float"></i>
            </a>

        </>
    );
}

export default Footer;
