import React, { useState, useEffect } from 'react';
import './style.css';
import cookieImage from "../../../assets/images/cookie-bite.svg";
const CookieConsent = () => {
    const [cookieAccepted, setCookieAccepted] = useState(false);

    useEffect(() => {
        const checkCookie = document.cookie.indexOf("CookieBy=CodingNepal");
        if (checkCookie !== -1) {
            setCookieAccepted(true);
        }
    }, []);

    const acceptCookie = () => {
        document.cookie = "CookieBy=CodingNepal; max-age=" + 60 * 60 * 24 * 30;
        if (document.cookie) {
            setCookieAccepted(true);
        } else {
            alert("Cookie can't be set! Please unblock this site from the cookie setting of your browser.");
        }
    };

    return (
        <div className={`wrapper ${cookieAccepted ? 'hide' : ''}`}>
            <img src={cookieImage} alt="" />
            <div className="content">
                <header>Cookies Consent</header>
                <p>This website use cookies to ensure you get the best experience on our website.</p>
                <div className="buttons">
                    <button className="item" onClick={acceptCookie}>I understand</button>
                    <a href="/cookie-policy" className="item">Learn more</a>
                </div>
            </div>
        </div>
    );
};

export default CookieConsent;
