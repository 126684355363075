import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../components/main/header";
import Footer from "../components/main/footer";

const Layout = () => {
  const location = useLocation();

  // Define paths that do not require Header and Footer
  const noHeaderFooterRoutes = [
    "/support/login",
    // Add other paths here
  ];

  const isNoHeaderFooter = noHeaderFooterRoutes.includes(location.pathname);

  return (
    <>
      {!isNoHeaderFooter && <Header />}
      <main>
        <Outlet />
      </main>
      {!isNoHeaderFooter && <Footer />}
    </>
  );
};

export default Layout;
