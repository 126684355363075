import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import FontAwesome from "react-fontawesome";
import ReactLogo from "../../../assets/images/logo.svg";
import { NavLink } from "react-router-dom";
import { CiLogout } from "react-icons/ci";
import Translate from "../../translate/Translate";

function Header() {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isDropdownOpen1, setDropdown1Open] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const userId = sessionStorage.getItem("user_id"); // Use sessionStorage here
  const userName = sessionStorage.getItem("user_name"); // Use sessionStorage here
  const isLoggedIn = userId && userName;
  const navigate = useNavigate(); // Get the navigate function

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleMenuItemClick = () => {
    setMenuOpen(false); // Close the menu when a menu item is clicked
  };
  const handleMouseEnter = (event) => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };

  const handleMouseEnter1 = (event) => {
    setDropdown1Open(true);
  };

  const handleMouseLeave1 = () => {
    setDropdown1Open(false);
  };

  function handleLogout() {
    // Clear session storage
    sessionStorage.removeItem("user_id");
    sessionStorage.removeItem("user_name");

    // Redirect to the login or home page
    navigate("/login"); // You can change '/login' to the appropriate route
  }

  return (
    <div className="header">
       <div className="display-desktop topbar-nav-bar">
        <div className="container">
          <div className=" top-navigation-area">
            <a href="mailto:support@federalboxx.com" target="_blank">
              <FontAwesome
                className="super-crazy-colors"
                name="envelope"
                size="18px"
              />
              support@federalboxx.com
            </a>
            <div>            <marquee>Awarded Most Innovative Networking Platform in the Maritime Industry</marquee>
</div>
            <div className="social-links">
              <ul>
                <li>
                  <a
                    href="https://www.facebook.com/federalboxx"
                    target="_blank"
                  >
                    <FontAwesome
                      className="super-crazy-colors"
                      name="facebook"
                      size="18px"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/federalboxx/?viewAsMember=true"
                    target="_blank"
                  >
                    <FontAwesome
                      className="super-crazy-colors"
                      name="linkedin"
                      size="18px"
                    />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/federalboxx" target="_blank">
                    <FontAwesome
                      className="super-crazy-colors"
                      name="twitter"
                      size="18px"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/federalboxx/"
                    target="_blank"
                  >
                    <FontAwesome
                      className="super-crazy-colors"
                      name="instagram"
                      size="18px"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCmopB6kO5Mu1Sdved-nZHzA"
                    target="_blank"
                  >
                    <FontAwesome
                      className="super-crazy-colors"
                      name="youtube-play"
                      size="18px"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container  display-desktop">
        <div className="navigation-area">
          <div className="logo-section">
            <NavLink to={"/"}>
              <img src={ReactLogo} alt="Logo" />
            </NavLink>
          </div>
          <div className="navigation-menu">
            <nav className="navbar navbar-expand-lg navbar-light ">
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav">
                  <li className="nav-item ">
                    <NavLink to={"/free-listing"}>Free Listing</NavLink>
                  </li>
                  <li className="nav-item ">
                    <NavLink to={"/directory"}>Directory</NavLink>
                  </li>
                  <li className="nav-item ">
                    <NavLink to={"/services"}>Services</NavLink>
                  </li>
                  <li
                    className={`nav-item dropdown${
                      isDropdownOpen ? " show" : ""
                    }`}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded={isDropdownOpen ? "true" : "false"}
                    >
                      Advantages
                    </a>
                    <div
                      className={`dropdown-menu${
                        isDropdownOpen ? " show" : ""
                      }`}
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      <NavLink className="dropdown-item" to={"/shipping-leads"}>
                        Business Leads
                      </NavLink>
                      <NavLink className="dropdown-item" to={"/networking"}>
                        Networking
                      </NavLink>
                      <NavLink className="dropdown-item" to={"/faqs"}>
                        Faqs
                      </NavLink>
                    </div>
                  </li>

                  <li
                    className={`nav-item dropdown${
                      isDropdownOpen1 ? " show" : ""
                    }`}
                    onMouseEnter={handleMouseEnter1}
                    onMouseLeave={handleMouseLeave1}
                  >
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdownMenuLink1"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded={isDropdownOpen1 ? "true" : "false"}
                    >
                      Company
                    </a>
                    <div
                      className={`dropdown-menu${
                        isDropdownOpen1 ? " show" : ""
                      }`}
                      aria-labelledby="navbarDropdownMenuLink1"
                    >
                      <NavLink className="dropdown-item" to={"/vision-mission"}>
                        Vision & Mission
                      </NavLink>

                      <NavLink className="dropdown-item" to={"/company"}>
                        How It works
                      </NavLink>
                    </div>
                  </li>
                  {isLoggedIn ? (
                    <li className="nav-item">
                      <NavLink to={"/contactus"}>Contact Us</NavLink>
                    </li>
                  ) : (
                    <li className="nav-item">
                      <NavLink to={"/membership"}>Pricing</NavLink>
                    </li>
                  )}
                  <li className="nav-item">
                    <NavLink to={"/partners"}>Partners</NavLink>
                  </li>
                  {/* <li className="nav-item">
                    <NavLink to={"/partners"}>Partners</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to={"/quote"}>Get Quote</NavLink>
                  </li> */}
                </ul>
              </div>
            </nav>
          </div>

          {/* <div className='user-access'>
            <div className='primary-button'> <NavLink to={"/membership/free/signup"}>Join Us</NavLink> </div>
            <div className='primary-button'> <NavLink to={"/login"}>Login</NavLink> </div>
          </div> */}
          {isLoggedIn ? (
            <div className="user-access d-flex">
              <div className="primary-button">
                <NavLink to="/dashboard">Dashboard</NavLink>
              </div>

              <div className="primary-button">
                <a className="text-wrap" onClick={handleLogout}>
                  logout
                </a>{" "}
                {/* Use button instead of NavLink */}
              </div>
            </div>
          ) : (
            <div className="user-access">
              <div className="primary-button">
                <NavLink to="/membership">Join Us</NavLink>
              </div>
              <div className="primary-button">
                <NavLink to="/login">Login</NavLink>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="display-mobile">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <div className="logo-section">
              <img src={ReactLogo} alt="Logo" />
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={handleToggleMenu} // Toggle menu open/close state
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={`collapse navbar-collapse ${menuOpen ? "show" : ""}`}
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <NavLink
                    onClick={handleMenuItemClick}
                    to={"/"}
                    className="nav-link"
                  >
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    onClick={handleMenuItemClick}
                    to={"/free-listing"}
                    className="nav-link"
                  >
                    Free Listing
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    onClick={handleMenuItemClick}
                    to={"/directory"}
                    className="nav-link"
                  >
                    Directory
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    onClick={handleMenuItemClick}
                    className="nav-link"
                    to={"/services"}
                  >
                    Services
                  </NavLink>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    // Close the menu when a dropdown item is clicked
                  >
                    Advantages
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <NavLink
                        to={"/shipping-leads"}
                        onClick={handleMenuItemClick}
                        className="dropdown-item"
                      >
                        {" "}
                        Business Leads
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"/networking"}
                        onClick={handleMenuItemClick}
                        className="dropdown-item"
                      >
                        Networking
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"/faqs"}
                        onClick={handleMenuItemClick}
                        className="dropdown-item"
                      >
                        Faqs
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    // Close the menu when a dropdown item is clicked
                  >
                    Company
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <NavLink
                        to={"/vision-mission"}
                        onClick={handleMenuItemClick}
                        className="dropdown-item"
                      >
                        {" "}
                        Vision & Mission
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"/company"}
                        onClick={handleMenuItemClick}
                        className="dropdown-item"
                      >
                        How it works
                      </NavLink>
                    </li>
                  </ul>
                </li>
                {isLoggedIn ? (
                  <li className="nav-item">
                    <NavLink
                      onClick={handleMenuItemClick}
                      className="nav-link"
                      to={"/contactus"}
                    >
                      Contact Us
                    </NavLink>
                  </li>
                ) : (
                  <li className="nav-item">
                    <NavLink
                      onClick={handleMenuItemClick}
                      className="nav-link"
                      to={"/membership"}
                    >
                      Pricing
                    </NavLink>
                  </li>
                )}
                <li className="nav-item">
                  <NavLink
                    onClick={handleMenuItemClick}
                    className="nav-link"
                    to={"/partners"}
                  >
                    Partners
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Header;